import React from "react";
import ModifyButton from "../../components/ui/button/ModifyButton";
import BasicCheckbox from "../../components/ui/checkbox/BasicCheckbox";
import importBtn from "./import-btn.png";
import exportBtn from "./liste-vierge-btn.png";
import clotureBtn from "./cloture-btn.png";
import totalBtn from "./total-declare-btn.png";
import paginationTab from "./pagination-tab.png";
import rechercheTab from "./recherche-tab.png";

const Declaration = () => {
  return (
    <div>
      <section className="section">
        <h3 className="section-title">Déclarer ses chiffres</h3>
        <div className="section-body">
          <p>Pour effectuer votre déclaration, vous avez deux possibilités :</p>
          <ul>
            <li>- la saisie manuelle</li>
            <li>- l'import de fichier Excel (xlsx)</li>
          </ul>
          <p>
            SI vous choisissez la <span>saisie manuelle</span>, il vous suffit
            de saisir vos chiffres directement sur la liste affichée en cliquant
            sur le bouton de modification en fin de chaque ligne.
          </p>
          <ModifyButton disabled />
          <p>
            Pendant votre saisie, vos données sont automatiquement enregistrées.
            Vous pouvez effectuer votre saisie en plusieurs fois et revenir sur
            vos précédentes saisie tant que votre déclaration n'est pas
            clôturée. <br />
          </p>
          <p>
            Si vous choisissiez de travailler sur
            <span> Excel </span>, vous pouvez récupérer la liste des magasins en
            cliquant sur "Liste vierge Excel"
          </p>
          <img src={exportBtn} alt="export-btn" className="btn-img" />
          <p>
            A partir de ce fichier, vous pouvez renseigner vos chiffres
            d'affaires en croisant avec vos éléments. Attention, vous devez
            respecter la structure originale du fichier (entêtes de colonnes) et
            les informations obligatoires (code magasin, Chiffres d'affaires).
            <br /> Vous devrez ensuite réimporter ce fichier dans l'interface
            pour valider et clôturer votre saisie.
          </p>
          <img src={importBtn} alt="import-btn" className="btn-img" />

          <p>
            Le chiffre d'affaires Ristournable est une donnée obligatoire. Notez
            que vous pouvez renseigner un chiffre d'affaires à Zéro "0", si
            c'est nécessaire.
          </p>
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Importer un fichier Excel</h3>
        <div className="section-body">
          <p>
            Si vous choisissez d'effectuer votre saisie sur Excel, nous vous
            recommandons d'utiliser le fichier Excel vierge fourni. <br />
            La première ligne permet au système d'identifier les données. Merci
            de respecter les intitulés de colonnes suivants :
          </p>
          <ul>
            <li>
              - <span>code (obligatoire)</span>: identifiant inédis du magasin
            </li>
            <li>
              - <span>c1</span>: chiffre d'affaires Non ristournable
            </li>
            <li>
              - <span>c2(obligatoire)</span>: chiffre d'affaires Ristournable
            </li>
            <li>
              - <span>c3, c4, c5, c6, c7, c8, c9</span>: chiffres d'affaires ou
              données complémentaires
            </li>
          </ul>
          <p>
            L'ordre des colonnes n'a pas d'importance. <br />
            <span>Toutes autres colonnes ne sont récupérées..</span>
          </p>
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Clôturer votre déclaration</h3>
        <div className="section-body">
          <p>
            Une fois votre déclaration terminée, il faut la clôturer pour
            qu'elle nous soit automatiquement envoyée.
          </p>
          <img src={clotureBtn} alt="cloture-btn" className="btn-img" />
          <p>
            Vous recevrez un <span>mail de confirmation</span>
            &nbsp;ainsi que la&nbsp;
            <span> copie de votre déclaration sous Excel</span>. Cette
            déclaration n'est alors plus modifiable, si toutefois vous vous
            apercevez d'une erreur ou d'un oubli. Vous avez la possibilité de
            contacter votre interlocuteur Inedis habituel, il pourra vous la
            rouvrir à nouveau.
          </p>
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Modifier une déclaration clôturée</h3>
        <div className="section-body">
          <p>
            Contactez votre interlocuteur Inédis habituel, il déverrouillera la
            saisie de votre déclaration.
          </p>
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Rechercher un magasin ou filtrer</h3>
        <div className="section-body">
          <p>
            Vous avez la possibilité de rechercher un magasin sur l'ensemble de
            ses critères (<span>Nom, Raison sociale, Code, Siret, etc.</span>)
          </p>
          <p>
            Vous pouvez également rechercher une Enseigne précise : (Compagnons,
            Territoires Nature, etc.)
          </p>
          <img src={rechercheTab} alt="recherche-tab" className="btn-img" />
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Affichage</h3>
        <div className="section-body">
          <p>
            Vous avez la possibilité de <span>trier</span> la liste affichée en
            cliquant sur l'entête des colonnes du tableau.
          </p>
          <p>
            En bas du tableau, vous pouvez sélectionner le
            <span> nombre de lignes </span> que vous souhaitez voir.
          </p>
          <img src={paginationTab} alt="pagination-tab" className="btn-img" />
          <p>
            Vous avez la possibilité de
            <span> sélectionner les magasins </span>
            avec lesquelles vous travailler pour pouvoir dans un second temps
            effectuer la saisie de vos chiffres de manière plus rapide à l'aides
            des checkbox en debut de ligne.
          </p>
          <BasicCheckbox />
          <p>
            Vous pouvez cocher celle en en-tête de colonne pour afficher
            uniquement les magasins sélectionnés.
          </p>
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Magasin(s) non trouvé(s)</h3>
        <div className="section-body">
          <p>
            Si malgré vos recherches, vous ne trouvez pas un magasin, vous avez
            la possibilité de nous l'indiquer en cliquant sur "magasin
            manquant".
          </p>
          <p>
            Vous pourrez nous renseigner son chiffre d'affaire, nous nous
            chargerons de le réaffecter.
          </p>
          <p>
            Attention, si vous avez choisi d'importer un fichier Excel, il vous
            faudra rajouter les magasins non trouvés manuellement.
          </p>
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Vérification</h3>
        <div className="section-body">
          <p>
            Vous pouvez facilement vérifier le montant total de votre
            déclaration en cliquant sur le bouton "total déclaré".
          </p>
          <img src={totalBtn} alt="total-declare-btn" className="btn-img" />
        </div>
      </section>

      <section className="section">
        <h3 className="section-title">Multi-contrats</h3>
        <div className="section-body">
          <p>
            Si nos accords donnent lieu à plusieurs contrats cadres, nous vous
            rappelons que vous devez réaliser une déclaration par contrat, et
            clôturer chacune de celle-ci.
          </p>
          <p>
            Avant d'accéder à l'écran de saisie, vous aurez la possibilité de
            choisir votre contrat. Si toutefois, il vous manquait un contrat,
            contactez votre interlocuteur Inedis habituel.
          </p>
        </div>
      </section>
      <section className="section">
        <h3 className="section-title">Mot de passe oublié</h3>
        <div className="section-body">
          <p>
            Si vous avez perdu votre mot de passe, vous pouvez le réinitialiser
            en vous rendant sur la page de connexion. "Mot de passe oublié"{" "}
            <br />
            Un lien de réinitialisation vous sera envoyé par mail.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Declaration;
