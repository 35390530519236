import React from "react";
import Modal from "../../../Modal";
import ARTimportFileForm from "./ARTimportFileForm";
import ImportIcon from "../../../../icons/ImportIcon";
import Button from "../../../../button/Button";
import useModal from "../../../../../../hooks/useModal";

const ARTimportListModal = ({ refreshData, dossier }) => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Importer un EXCEL"
        onClick={() => modalState.toggleModal()}
        color="color-tertiaire-variant"
        icon={<ImportIcon />}
      />
      <Modal
        modalState={modalState}
        title="Importer une liste de données EXCEL"
        color="excel-modal"
      >
        <ARTimportFileForm
          refreshData={refreshData}
          hide={modalState.toggleModal}
          dossier={dossier}
        />
      </Modal>
    </>
  );
};

export default ARTimportListModal;
