export const PATH = {
  HOME: "/",
  UNAUTHORIZED: "/unauthorized",
  NOT_FOUND: "/",
  // Auth
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password/:token/:email",
  FORGOT_PASSWORD: "/forgot-password",
  UPDATE_PASSWORD: "/profile",
  // RFA
  AIDE: "/aide",
  SELECTION_CONTRAT: "/rfa/selection",
  DECLARATION_DETAIL: "/rfa/declaration-details",
  LITIGE_DETAIL: "/rfa/litige-details",
  ARTICLE_DETAIL: "/rfa/article-details",

  // Administration
  DASHBOARD: "/administration",
  DASHBOARD_DECLARATIONS: "/administration/declaration-dashboard",
  DASHBOARD_USERS: "/administration/fournisseur-dashboard",
  DASHBOARD_LITIGES: "/administration/litige-dashboard",
  DASHBOARD_ARTICLES: "/administration/article-dashboard",
};

export const CONSTANTES={
  ANNEE_TRAITEMENT:'2024',
  DATE_FIN_DECLARATION :'31 Janvier 2025'
}
export const ROLE = {
  ADMIN: 1,
  REFERENCIA: 2,
  FOURNISSEUR: 3,
};

export const ERROR_MESSAGE = {
  REQUIRED: "* Ce champs est obligatoire",
  FORMAT: "* Format invalide (ex : 1000.52)",
  LONG_STRING: "* Le champ ne doit pas dépasser 255 caractères",
};

export const ANIMATION_CLASS = {
  SLIDE_OUT_RIGHT: "animate__animated animate__slideOutRight",
  SLIDE_IN_RIGHT: "animate__animated animate__slideInRight",
  SLIDE_OUT_LEFT: "animate__animated animate__slideOutLeft",
  SLIDE_IN_LEFT: "animate__animated animate__slideInLeft",
};

export const REGEX = {
  CURRENCY: /^-?\s*\d{1,9}(?:[ ,]\d{3})*(?:[.,]\d{1,2})?$/,
};
