import React from "react";

const Articles = () => {
  return (
    <div>
      <section className="section">
        <h3 className="section-title">Articles</h3>
        <div className="section-body">
          <p>
            Pour effectuer votre mise à jour tarifaire allez dans l'onglet
            <span> Articles </span> puis :
          </p>
          <ul>
            <li>Étape 1 : Téléchargez un fichier Excel.</li>
            <li>Étape 2 : Importez le fichier Excel complété.</li>
            <li>Étape 3 : Clôturez votre mise à jour.</li>
          </ul>
          <h3 className="section-title">Comment remplir le fichier ?</h3>

          <p>
            Pour mettre à jour votre base articles ou créer de nouveaux
            articles, procédez par importation d'un fichier Excel. <br />
          </p>
          <p>
            Dans le cadre d'une mise à jour, il est recommandé de télécharger la
            matrice de mise à jour en cliquant sur le bouton
            <span> Télécharger Excel </span> .
          </p>
          <p>
            Si vous utilisez votre propre fichier Excel, il est impératif de
            respecter l'entête du fichier (les noms des colonnes) comme indiqué
            dans le tableau.
          </p>
          <p>
            Le système identifie les articles grâce à leur gencod, qui doit être
            unique (aucun doublon n'est accepté). Pour les nouveaux articles
            (créations), vous pouvez les ajouter directement dans votre fichier.
            Vous trouverez ci-dessous les colonnes que vous pouvez mettre à jour
            et la manière dont elles doivent être renseignées :
          </p>
          {/* Tableau ajouté ici */}
          <table className="aide-table">
            <thead>
              <tr>
                <th>Colonne</th>
                <th>Titre</th>
                <th>Explication</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A</td>
                <td>Ref fournisseur</td>
                <td>
                  Le champ ref_fournisseur est obligatoire et ne doit pas
                  dépasser 50 caractères.
                </td>
              </tr>
              <tr>
                <td>B</td>
                <td>Gencod</td>
                <td>Le champ gencod est obligatoire.</td>
              </tr>
              <tr>
                <td>C</td>
                <td>Designation</td>
                <td>
                  Le champ désignation est obligatoire et ne doit pas dépasser
                  80 caractères.
                </td>
              </tr>
              <tr>
                <td>D</td>
                <td>Designation Caisse</td>
                <td>
                  Le champ désignation caisse ne doit pas dépasser 80
                  caractères.
                </td>
              </tr>
              <tr>
                <td>E</td>
                <td>Marque</td>
                <td>Le champ Marque ne doit pas dépasser 80 caractères.</td>
              </tr>
              <tr>
                <td>F</td>
                <td>TVA</td>
                <td>
                  Le champ TVA est obligatoire et ne peut être que 5,5, 10 ou
                  20.
                </td>
              </tr>
              <tr>
                <td>G</td>
                <td>PA BRUT</td>
                <td>Le champ Brut est obligatoire.</td>
              </tr>
              <tr>
                <td>H</td>
                <td>REMISE</td>
                <td>
                  Le champ Remise est obligatoire. Il doit être un pourcentage
                  valide (entre 0 et 100).
                </td>
              </tr>
              <tr>
                <td>J</td>
                <td>Date Début Validite</td>
                <td>Le champ Date Début Validité est obligatoire.</td>
              </tr>
              <tr>
                <td>K</td>
                <td>Date Fin Validite</td>
                <td>Le champ Date Fin Validité est obligatoire.</td>
              </tr>
              <tr>
                <td>N</td>
                <td>Unite Achat</td>
                <td>
                  Le champ Unité Achat ne peut être que UVC, CAR, PAL, LOT, BOX,
                  ROU, PRE.
                </td>
              </tr>
              <tr>
                <td>Q</td>
                <td>Unité Mesure</td>
                <td>
                  Le champ Unité Mesure ne peut être que UN, L, KG, M, M².
                </td>
              </tr>
              <tr>
                <td>T</td>
                <td>Nouveauté</td>
                <td>
                  Si l'article est une nouveauté, tapez ou sélectionnez "Oui".
                </td>
              </tr>
              <tr>
                <td>U</td>
                <td>Suppression</td>
                <td>
                  Si l'article doit être supprimé, tapez ou sélectionnez "Oui".
                </td>
              </tr>
            </tbody>
          </table>
          {/* Mettre tableau ici */}
          <p>
            Lors de l'importation le système vérifiera la complétude de votre
            fichier. Si des informations sont incorrectes ou manquantes, le
            fichier sera refusé. Les erreurs vous seront indiquées.
          </p>
          <h3 className="section-title">Nomenclature :</h3>
          <p>
            Vous pouvez télécharger notre nomenclature (Rayon, Famille, etc.).
            Bien que ces informations ne soient pas obligatoires pour la
            création d'articles, elles sont fortement recommandées. Ces données
            améliorent la visibilité de vos articles sur notre extranet et
            facilitent leur intégration dans les systèmes de points de vente de
            nos affiliés.
          </p>
          <h3 className="section-title">Clôturer votre mise à jour :</h3>
          <p>
            Une fois votre fichier complété et validé par le système, cliquez
            sur le bouton "Clôturer". Le chef de produit en charge de votre
            contrat sera notifié et vous recevrez une copie des données saisies.
            Pour des créations d'articles en cours d'année, il n'est pas
            nécessaire de resoumettre toutes les lignes du fichier. Vous pouvez
            simplement soumettre les nouvelles créations avant de clôturer.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Articles;
