import React from 'react';
import './style.scss';

const ContratSelect = ({
  label,
  onChange,
  children,
  title,
  error = '',
  ...rest
}) => {
  return (
    <div className="contrat-select">
      <select {...rest} onChange={onChange}>
        {children}
      </select>
      <span className="error">{error ? <>{error}</> : null}</span>
    </div>
  );
};

export default ContratSelect;
