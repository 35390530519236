import React, { useState, useEffect } from "react";
import Button from "../../button/Button";
import Modal from "../Modal";
import Contratselect from "./Contratselect";
import "./style.scss";
import { getContrats } from '../../../../services/contrat_apis';

const AddModal = ({ onConfirmation, icon, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contratsList, setContratsList] = useState([]);
  const [selectedContrat, setSelectedContrat] = useState("");

  useEffect(() => {
    const fetchContrats = async () => {
      try {
        const contrats = await getContrats();
        setContratsList(contrats);
        if (contrats.length > 0) {
          setSelectedContrat(contrats[0].con_code);
        }
      } catch (err) {
        console.error("Erreur lors de la récupération des contrats :", err);
        setError("Une erreur est survenue lors de la récupération des contrats.");
      }
    };

    fetchContrats();
  }, []);

  const handleChange = (event) => {
    setSelectedContrat(parseInt(event.target.value));
  };

  const handleClick = () => {
    setLoading(true);
    setError("");
    onConfirmation(name,email,selectedContrat)
      .then(() => props.modalState.toggleModal())
      .catch((error) => {
        console.error(error);
        setError("Une erreur est survenue");
      })
      .finally(() => {
        setLoading(false);
      });
      setName("")
      setEmail("")
      setSelectedContrat("")
  };

  if (contratsList.length === 0) {
    return <p>Chargement des contrats...</p>;
  }

  return (
    <Modal {...props}>
      <div className="adduser-modal-wrap">
        <div className="adduser-modal">
          <div className="adduser-modal__content">
            <br />
            <p className="adduser-label">Veuillez renseigner un nom</p>
            <input
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Entrez un nom"
            />
            <p className="adduser-label">Veuillez renseigner un email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Entrez un email"
            />
             <p className="adduser-label">Veuillez sélectionner un contrat</p>
            <Contratselect
              name="con_code"
              label="Contrat"
              value={selectedContrat}
              onChange={handleChange}
            >
              {contratsList.map((contrat) => (
                <option key={contrat.con_code} value={contrat.con_code}>
                  {contrat.con_nom}
                </option>
              ))}
            </Contratselect>
          </div>

          <div className="adduser-modal__action">
            <p>{error}</p>
            <div>
              <Button
                libelle="Annuler"
                color="color-neutral"
                onClick={props.modalState.toggleModal}
              />
              <Button
                libelle="Ajouter"
                onClick={() => handleClick()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddModal;
