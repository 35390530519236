import React from "react";
import useModal from "../../../../../../hooks/useModal";
import Button from "../../../../button/Button";
import DownloadIcon from "../../../../icons/DownloadIcon";
import Modal from "../../../Modal";
import ARTdownloadListCard from "./ARTdownloadListCard";

const ARTdownloadNomenclatureModal = () => {
  const modalState = useModal();

  return (
    <>
      <Button
        libelle="Télécharger Nomenclature"
        onClick={() => modalState.toggleModal()}
        color="excel-variant-btn"
        icon={<DownloadIcon />}
      />
      <Modal
        modalState={modalState}
        title="Télécharger la liste des nomenclatures"
        color="excel-modal"
      >
        <ARTdownloadListCard
          hide={modalState.toggleModal}
        />
      </Modal>
    </>
  );
};

export default ARTdownloadNomenclatureModal;
