import React, { useState } from "react";
import "./style.scss";
import Table from "../../../Table";
import DeleteIcon from "../../../../icons/DeleteIcon";
import EditIcon from "../../../../icons/EditIcon";
import DownloadIcon from "../../../../icons/DownloadIcon";

const ARTarticleTable = ({ article }) => {
  const [filterType, setFilterType] = useState("all");

  const isModified = (item) => {
    const fieldsToCheck = [
      "Att_Brut",
      // "Att_Remise",
      // "Att_Net",
      // "Att_Coeff",
      // "Att_PVS",
      // "Att_Eco_HT",
      // "Att_Eco_TTC",
      // "Att_Valid_Debut",
      // "Att_Valid_Fin",
    ];
    return fieldsToCheck.some((field) => item[field] && item[field] !== 0);
  };

  const totalArticles = article.length;
  const modifiedArticlesCount = article.filter(isModified).length;
  const modifiedPercentage =
    totalArticles > 0
      ? ((modifiedArticlesCount / totalArticles) * 100).toFixed(2)
      : 0;

  const filteredArticles = article.filter((item) => {
    switch (filterType) {
      case "modified":
        return isModified(item);
      case "notModified":
        return !isModified(item);
      case "deleted":
        return item.Suppression === "OUI";
      default:
        return true;
    }
  });

  const columns = React.useMemo(() => [
    {
      Header: "Référence",
      accessor: "Reference",
      width: 70,
    },
    {
      Header: "Gencod",
      accessor: "Gencod",
      width: 70,
    },
    {
      Header: "Désignation",
      accessor: "Designation",
      width: 250,
    },
    // {
    //   Header: "Marque",
    //   accessor: "Marque",
    //   width: 100,
    //   hideable: false,
    // },
    // {
    //   Header: "Caisse",
    //   accessor: "Caisse",
    //   width: 100,
    //   hideable: true,
    // },
    {
      Header: "PA Brut",
      accessor: "Brut",
      width: 100,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} €`;
      },
    },
    {
      Header: "Remise",
      accessor: "Remise",
      width: 50,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} %`;
      },
    },
    // {
    //   Header: "TVA",
    //   accessor: "TVA_TAUX",
    //   width: 100,
    //   hideable: true,
    // },
    // {
    //   Header: "Net",
    //   accessor: "Net",
    //   width: 100,
    //   hideable: true,
    //   Cell: ({ value }) => `${parseFloat(value).toFixed(2)} €`,
    // },
    // {
    //   Header: "PVS",
    //   accessor: "PVS",
    //   width: 100,
    //   hideable: true,
    //   Cell: ({ value }) => `${parseFloat(value).toFixed(2)} €`,
    // },
    // {
    //   Header: "Eco HT",
    //   accessor: "Eco_HT",
    //   width: 100,
    //   hideable: true,
    //   Cell: ({ value }) => `${parseFloat(value).toFixed(2)} €`,
    // },
    // {
    //   Header: "Eco TTC",
    //   accessor: "Eco_TTC",
    //   width: 100,
    //   hideable: true,
    //   Cell: ({ value }) => `${parseFloat(value).toFixed(2)} €`,
    // },
    {
      Header: "Début Validité",
      accessor: "Valid_Debut",
      width: 100,
      Cell: ({ value }) => {
        if (!value) return "-";
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
    },
    {
      Header: "Fin Validité",
      accessor: "Valid_Fin",
      width: 100,
      Cell: ({ value }) => {
        if (!value) return "-";
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
    },
    {
      Header: "PA Brut",
      accessor: "Att_Brut",
      width: 100,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} €`;
      },
      hideable: true,
    },
    {
      Header: "Remise",
      accessor: "Att_Remise",
      width: 50,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} %`;
      },
      hideable: true,
    },
    {
      Header: "Net",
      accessor: "Att_Net",
      width: 100,
      hideable: true,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} €`;
      },
    },
    {
      Header: "Coeff",
      accessor: "Att_Coeff",
      width: 100,
      hideable: true,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      Header: "PVS",
      accessor: "Att_PVS",
      width: 100,
      hideable: true,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} €`;
      },
    },
    {
      Header: "Eco_HT",
      accessor: "Att_Eco_HT",
      width: 100,
      hideable: true,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} €`;
      },
    },
    {
      Header: "Eco TTC",
      accessor: "Att_Eco_TTC",
      width: 100,
      hideable: true,
      Cell: ({ value }) => {
        if (value === null || value === undefined) return "-";
        return `${parseFloat(value).toFixed(2)} €`;
      },
    },
    {
      Header: "Début Validité",
      accessor: "Att_Valid_Debut",
      width: 100,
      Cell: ({ value }) => {
        if (!value) return "-";
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
      hideable: true,
    },
    {
      Header: "Fin Validité",
      accessor: "Att_Valid_Fin",
      width: 100,
      Cell: ({ value }) => {
        if (!value) return "-";
        const dateParts = value.split("-");
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      },
      hideable: true,
    },
    {
      Header: "Statut",
      accessor: "Statut",
      width: 50,
      Cell: ({ row }) => {
        const item = row.original;
        if (item.Suppression === "OUI") {
          return (
            <span className="status-badge deleted">
              <DeleteIcon color="white" /> Supprimé
            </span>
          );
        } else if (item.Article_Code === null) {
          return (
            <span className="status-badge created">
              <DownloadIcon /> Création
            </span>
          );
        } else if (isModified(item)) {
          return (
            <span className="status-badge updated">
              <EditIcon /> Mise à jour
            </span>
          );
        } else {
          return <span>-</span>;
        }
      },
      hideable: true,
    },
  ]);

  const defaultHiddenColumns = [
    // "Marque",
    // "Caisse",
    // "TVA_TAUX",
    // "Net",
    // "PVS",
    // "Eco_HT",
    // "Eco_TTC",
    "Att_Net",
    "Att_Coeff",
    "Att_PVS",
    "Att_Eco_HT",
    "Att_Eco_TTC",
  ];

  return (
    <>
      <div className="article-table-overlay">
        <div className="modified-percentage">
          Pourcentage d'articles modifiés :{" "}
          <strong>{modifiedPercentage}%</strong>
        </div>
        <div className="filter-buttons">
          <div className="checkbox">
            <label className="filter-checkbox">
              <input
                type="checkbox"
                checked={filterType === "modified"}
                onChange={() =>
                  setFilterType(filterType === "modified" ? "all" : "modified")
                }
              />
              Articles modifiés
            </label>
          </div>

          <div className="checkbox">
            <label className="filter-checkbox">
              <input
                type="checkbox"
                checked={filterType === "notModified"}
                onChange={() =>
                  setFilterType(
                    filterType === "notModified" ? "all" : "notModified"
                  )
                }
              />
              Articles non modifiés
            </label>
          </div>

          <div className="checkbox">
            <label className="filter-checkbox">
              <input
                type="checkbox"
                checked={filterType === "deleted"}
                onChange={() =>
                  setFilterType(filterType === "deleted" ? "all" : "deleted")
                }
              />
              Articles supprimés
            </label>
          </div>
        </div>

        <div className="article-table">
          <Table
            data={filteredArticles}
            columns={columns}
            hiddenColumns={defaultHiddenColumns}
            filter
            pagination
            defaultPageSize={100}
            pageSizeOptions={[100, 200, 500]}
            search={{
              placeholder: "Ref fournisseur, Gencod, désignation ...",
            }}
            showLegend={true}
            showTotal={true}
          />
          {filteredArticles.length === 0 && (
            <div className="no-data-message">Aucun article trouvé.</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ARTarticleTable;
