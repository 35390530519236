import React, { useState } from "react";
import useModal from "../../../../../../hooks/useModal";
import useNotification from "../../../../../../hooks/useNotification";
import { addUser } from "../../../../../../services/user_apis";
import AddModal from "../../../../modals/AddModal";
import Button from "../../../../button/Button";
import BasicNotification from "../../../../notifications/BasicNotification";

const AddUser = ({ refreshData }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();
  const [textNotification, setTextNotification] = useState("");
  const [typeNotification, setTypeNotification] = useState("success");

  // const handleAddUser = async (name, email, contrat) => {
  //   console.log({"name": name, "email": email, "contrat": contrat});
  //   try {
  //     await addUser({"name": name, "email": email, "contrat": contrat});
  //     toggleNotification();
  //     modalState.toggleModal();
  //     refreshData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleAddUser = async (name, email, contrat) => {  
    try {
      const response = await addUser({ "name": name, "email": email, "contrat": contrat });
      console.log(response);
      if (response.status === 201) {
        setTextNotification('Utilisateur ajouté avec succès', 'success')
        toggleNotification('Utilisateur ajouté avec succès', 'success');
        modalState.toggleModal();
        refreshData();
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setTextNotification('Cet email est déjà utilisé par un autre utilisateur', 'error')
        setTypeNotification("error")
        toggleNotification('Cet email est déjà utilisé par un autre utilisateur', 'error');
      } else {
        setTextNotification('Une erreur est survenue lors de la création de l\'utilisateur', 'error')
        setTypeNotification("error")
        toggleNotification('Une erreur est survenue lors de la création de l\'utilisateur', 'error');
      }
      console.log( error.response.status);
    }
  };


  return (
    <>
      <Button
        libelle="Ajouter"
        onClick={() => modalState.toggleModal()}
        color="primary-btn"
      />
      <AddModal
        modalState={modalState}
        title="Ajouter un nouvel utilisateur"
        color="primary-modal-bis"
        onConfirmation={handleAddUser}
      />

      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text={textNotification}
        type={typeNotification}
      />
    </>
  );
};

export default AddUser;
