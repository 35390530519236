import React, { useState } from "react";
import Declaration from "./Declaration";
import Articles from "./Articles";
import "./style.scss";

const Aide = () => {
  const [activeSection, setActiveSection] = useState("declaration");

  const renderSection = () => {
    switch (activeSection) {
      case "declaration":
        return <Declaration />;
      case "articles":
        return <Articles />;
      default:
        return <Declaration />;
    }
  };

  return (
    <div className="aide-screen">
      <div className="aide">
        <h1 className="aide-title">Aide</h1>
        <div className="aide-navigation">
          <button
            onClick={() => setActiveSection("declaration")}
            className={activeSection === "declaration" ? "active" : ""}
          >
            Déclaration
          </button>
          <button
            onClick={() => setActiveSection("articles")}
            className={activeSection === "articles" ? "active" : ""}
          >
            Articles
          </button>
        </div>
        <div className="aide-content">{renderSection()}</div>
      </div>
    </div>
  );
};

export default Aide;
