import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import logoIcon from "./logo-referencia.png";
import Hamburger from "./Hamburger";
import useAuth from "../../../../hooks/useAuth";
import NavLink from "./NavLink";
import { PATH } from "../../../../config/constants";
import { getContrats } from "../../../../services/contrat_apis";
import { getArticlesByContrat } from "../../../../services/articles_api";

const Nav = () => {
  const { user, isAdmin, isReferencia, onLogout } = useAuth();
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(-1);

  const [listArticles, setListArticles] = useState([]);
  const [dossier, setListDossier] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = () => {
    setSelectedLinkIndex(-1);
    onLogout();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupérer les contrats
        const contrats = await getContrats();

        let selectedContrat = null;

        if (contrats.length === 1) {
          selectedContrat = contrats[0];
        } else if (contrats.length > 1) {
          // Implémentez la logique de sélection du contrat par l'utilisateur ici
          selectedContrat = contrats[0]; // Par exemple, sélectionnez le premier contrat
        } else {
          console.log("Pas de contrats disponibles.");
        }

        // Récupérer les articles si un contrat est sélectionné
        if (selectedContrat?.con_code) {
          const data = await getArticlesByContrat(selectedContrat.con_code);
          setListArticles(data.articles || []);
          setListDossier(data.dossier || null);
        } else {
          console.log("Aucun contrat valide pour récupérer les articles.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const links = [
    {
      label: "Accueil",
      to: PATH.HOME,
    },
    {
      label: "Déclaration",
      to: PATH.DECLARATION_DETAIL,
      forward: true,
    },
    {
      label: "Litiges",
      to: PATH.LITIGE_DETAIL,
      forward: true,
    },
    dossier && Object.keys(dossier).length > 0 && { // on affiche l'onglet ARticles uniquement si le founisseur à des dossiers
      label: "Articles",
      to: PATH.ARTICLE_DETAIL,
      forward: true,
  },
    {
      label: "Tableau de bord",
      to: PATH.DASHBOARD,
      visible: isAdmin() || isReferencia(),
    },
    {
      label: "Aide",
      to: PATH.AIDE,
    },
  ].filter(Boolean);

  const manageLinks = [
    {
      label: user.name,
      items: [
        { label: "Profile", to: "/profile" },
        { label: "Deconnexion", onClick: handleLogout },
      ],
    },
  ];

  const handleClick = (event) => {
    const { target } = event;
    if (!target.closest(".dropdown")) {
      setSelectedLinkIndex(-1);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  if (isLoading) {
    return null; // Ou affichez un indicateur de chargement
  }

  return (
    <>
      <nav className="nav">
        <div className="nav__links">
          <Link to="/">
            <img className="nav__logo" src={logoIcon} alt="logo" />
          </Link>
          <ul className="dropdown">
            {links.map((link, index) => (
              <NavLink
                key={link.label}
                link={link}
                selected={selectedLinkIndex === index}
                visible={link.visible}
                onClick={() => setSelectedLinkIndex(index)}
                forward={link.forward}
              />
            ))}
          </ul>
        </div>
        <div className="nav__actions">
          <ul className="dropdown">
            {manageLinks.map((link, index) => (
              <NavLink
                key={link.label}
                link={link}
                selected={selectedLinkIndex === index}
                onClick={() => setSelectedLinkIndex(index)}
              />
            ))}
          </ul>
        </div>
        <div className="nav__burger">
          <Hamburger />
        </div>
      </nav>
    </>
  );
};

export default Nav;
