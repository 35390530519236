import React from "react";
import "./style.scss";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import { useSticky } from "react-table-sticky";
import Pagination from "./Pagination";
import Cell from "./Cell";
import Filters from "./Filters";
import Search from "./Search";

const Table = ({
  data,
  columns,
  pagination,
  defaultPageSize = 20,
  pageSizeOptions = [20, 50, 100, 200],
  filter,
  search,
  footer,
  updateMyData,
  hiddenColumns,
  getRowProps,
  showLegend = false,
  showTotal = false,
}) => {
  // Noms des classes
  const stickyCellClass = "action-cell";
  const leftBorderClass = "border-left-cell";
  const rightBorderClass = "border-right-cell";
  const borderedHeaderClass = "separated";

  const props = useTable(
    {
      columns: columns,
      data,
      defaultColumn: { Cell: Cell },
      updateMyData,
      initialState: {
        hiddenColumns: hiddenColumns ?? [],
        pageSize: defaultPageSize,
      },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSticky,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    setGlobalFilter,
    page,
    state: { globalFilter },
  } = props;

  const getCellStyle = (column) => ({
    minWidth: column.width,
    maxWidth: column.width,
    textAlign: column.align,
  });

  const getHeaderStyle = (column) => ({
    minWidth: column.width,
    cursor: "pointer",
  });

  const getHeaderClass = (headerGroup, i) => {
    const headerClass = [];
    headerClass.push(
      i !== headerGroup.headers.length - 1 && borderedHeaderClass
    );

    return headerClass.join(" ");
  };

  const getCellClass = (cell) => {
    const cellClass = [];

    const cellStickyPosition = cell.column.sticky;
    cellClass.push(cellStickyPosition && stickyCellClass);
    switch (cellStickyPosition) {
      case "left":
        cellClass.push(rightBorderClass);
        break;
      case "right":
        cellClass.push(leftBorderClass);
        break;
      default:
    }
    // Ajout de classes conditionnelles en fonction de l'accessor
    if (
      ["Brut", "Remise", "Valid_Debut", "Valid_Fin", "TVA_TAUX", "Net", "PVS", "Eco_HT", "Eco_TTC",].includes(cell.column.id)
    ) {
      cellClass.push("body-cell green-pastel-bg");
    } else if (
      ["Att_Brut", "Att_Remise", "Att_Valid_Debut", "Att_Valid_Fin", "Att_Net", "Att_Coeff", "Att_PVS", "Att_Eco_HT", "Att_Eco_TTC",].includes(
        cell.column.id
      )
    ) {
      cellClass.push("body-cell yellow-pastel-bg");
    }
    //cellClass.push("cell-border");

    return cellClass.join(" ");
  };

  if (!columns.length || !data.length) return null;
  return (
    <div className="table-overlay">
      <div className="table-overlay__head">
        {search && (
          <Search
            value={globalFilter}
            setValue={setGlobalFilter}
            placeholder={search.placeholder}
          />
        )}
        {filter && <Filters allColumns={allColumns} />}
        {showLegend || showTotal ? (
          <div className="head-row-flex">
            {showLegend && (
              <div className="legend">
                <span className="legend-item green-background">
                  Tarif actuel
                </span>
                <span className="legend-item yellow-background">
                  Tarif en attente
                </span>
              </div>
            )}

            {showTotal && (
              <div className="total-articles">
                Nombre total: <strong>{data.length}</strong>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className="table-overlay__body">
        <div className="table-wrapper">
          <table className="table" {...getTableProps()}>
            <thead className="table__head">
              {headerGroups.map((headerGroup) => (
                <tr className="head-row" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <th
                      className={`head-cell ${getHeaderClass(headerGroup, i)}`}
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        title: "",
                        style: getHeaderStyle(column),
                      })}
                    >
                      <span>{column.render("Header")}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="table__body" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const rowProps = getRowProps
                  ? {
                    ...row.getRowProps(),
                    ...getRowProps(row),
                  }
                  : row.getRowProps();
                return (
                  <tr
                    {...rowProps}
                    className={`body-row ${rowProps.className}`}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          title={cell.value}
                          className={`body-cell ${getCellClass(cell)}`}
                          {...cell.getCellProps({
                            className: getCellClass(cell),
                            style: getCellStyle(cell.column),
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {pagination && (
          <Pagination
            props={props}
            pageSizeOptions={pageSizeOptions}
            footer={footer}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
