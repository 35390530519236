import React from "react";
import "./style.scss";
import { useOutletContext } from "react-router-dom";
import ADMDossiersTable from "../../../components/ui/tables/_Administration/ADMarticle/ADMcontratsRFATable";

const DashboardArticle = () => {
  const { dossiers } = useOutletContext();
  const { refetchDossiers } = useOutletContext();

  return (
    <>
      <div className="dashboard-rfa-screen">
        <div className="dashboard-rfa">
          <div className="dashboard-rfa__head">
            <h3 className="title">Tarifications des Articles</h3>
          </div>
          <div className="dashboard-rfa__table">
            <ADMDossiersTable
              data={dossiers}
              refreshData={refetchDossiers}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardArticle;
