import React from "react";
import useModal from "../../../../../hooks/useModal";
import useNotification from "../../../../../hooks/useNotification";
import { rappelArticle } from "../../../../../services/articles_api";
import MailIcon from "../../../icons/MailIcon";
import BasicNotification from "../../../notifications/BasicNotification";
import Button from "../../../button/Button";
import ValidationModal from "../../ValidationModal";

const ADMrappelArticleModal = ({ data }) => {
  const modalState = useModal();
  const { isShowingNot, toggleNotification } = useNotification();
  /**
   * Fonction renvoyant un mail de rappel pour la cloture des mise a jour tarifaire
   * @param data le contrat concernée
   */
  const handleRappelCloture = async () => {
    try {
      await rappelArticle(data.Dossier_Code);
      toggleNotification();
      modalState.toggleModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        libelle="Rappel"
        onClick={() => modalState.toggleModal()}
        color="color-tertiaire-variant"
        icon={<MailIcon with={15} height={15} />}
      />
      <ValidationModal
        modalState={modalState}
        onConfirmation={() => handleRappelCloture()}
        title="Mail rappel de déclaration"
      >
        <>Envoyer le rappel de déclaration pour {data.nom_fournisseur}</>
      </ValidationModal>
      <BasicNotification
        isShowing={isShowingNot}
        hide={toggleNotification}
        text="Mail envoyé"
      />
    </>
  );
};

export default ADMrappelArticleModal;
